import * as angular from "angular";
import { IAttributes, ICompileService, IScope } from "angular";
import Dropzone, { DropzoneFile } from "dropzone";
import { Injectables } from "../../configuration/injectables";
import app from "../../main";
import { JQueryService } from "../../utilities/jquery/jQueryService";
import { LocalStorageService } from "../../utilities/localStorage/localStorageService";
import { ToastMessageCreator } from "../../utilities/toastMessages/toastMessageCreator";
import { FileUploadMetadata } from "../documentPicker/documentPicker";

class DropZoneController {
    
    public static $inject = [
        Injectables.ToastMessageCreator, 
        Injectables.LocalStorageService, 
        Injectables.$scope,
        Injectables.JQueryService
    ];

    constructor(
        private readonly toastMessageCreator: ToastMessageCreator,
        private readonly localStorageService: LocalStorageService,
        private readonly $scope: DropZoneDirectiveScope,
        private readonly jQueryService: JQueryService
    ) {
    }

    public dzId: string;
    public showProgress: boolean;
    public dropZone: Dropzone;
    public createImageThumbnails: boolean;
    public maxFilesize: number;
    public maxFiles: number;
    public acceptedFiles: string;
    public autoProcessQueue: boolean;
    public parallelUploads: number;
    public uploadMultiple: boolean;

    // todo:
    // need to remove this custom function. it was added manually to the dropzone.js file that was
    // referenced in the project. We now use an npm installed version that doesn't contain the function
    public addFilesStart: (param?: { files: DropzoneFile[], proceed: () => void }) => Dropzone; // '&?',

    public addedFile: (param?: { file: DropzoneFile}) => any; // '&?',
    public canceled: (param?: { file: DropzoneFile}) => any; // '&?',
    public complete: (param?: { file: DropzoneFile}) => any; // '&?',
    public drop: (param?: {event: DragEvent}) => any; // '&?',
    public dropStart: (param?: {event: DragEvent}) => any; // '&?',
    public dragEnd: (param?: {event: DragEvent}) => any; // '&?',
    public dragEnter: (param?: {event: DragEvent}) => any; // '&?',
    public dragOver: (param?: {event: DragEvent}) => any; // '&?',
    public dragLeave: (param?: {event: DragEvent}) => any; // '&?',
    public done: (param?: {file: DropzoneFile}) => any; // '&?',
    public error: Dropzone; // '&?',
    public formData: () => void; // '&?',
    public files: FileUploadMetadata[]; // '=?',
    public maxFilesSearched: (param?: {file: DropzoneFile[]}) => any; // '&?',
    public maxFilesExceeded: (param?: {file: DropzoneFile}) => any; // '&?',
    public processing: (param?: {file: DropzoneFile}) => any; // '&?',
    public queueComplete: () => any; // '&?',
    public removedFile: (param?: { file: DropzoneFile}) => any; // '&?',
    public reset: () => void; // '&?',
    public sending: (param?: any) => any; // '&?',
    public success: (param?: { file: DropzoneFile, response: any }) => any; // '&?',
    public thumbnail: (param?: { file: DropzoneFile}) => any; // '&?',
    public totalUploadProgress: (param?: any) => any; // '&?',
    public uploadingFiles: FileUploadMetadata[]; // '=?',
    public uploadProgress: (param?: any) => any; // '&?',
    public currentPercentComplete: number; // '=?',
    public percentComplete: number; // '=?',
    public proceed: () => void;
    
    public initializeDropzone = () => {
        if (!this.dzId) {
            return;
        }

        this.showProgress = this.showProgress === true ? this.showProgress : false;

        this.dropZone = new Dropzone(
            '#' + this.dzId,
            {
                url: "will be set onProcessing",
                headers: { "Authorization": "Bearer " + this.localStorageService.getAuthenticationData().token },
                clickable: '#' + this.dzId + ' *',
                createImageThumbnails: this.createImageThumbnails === true ? this.createImageThumbnails : false,
                maxFilesize: this.maxFilesize || 50,
                maxFiles: this.maxFiles || 10,
                acceptedFiles: this.acceptedFiles || 'application/pdf',
                autoProcessQueue: this.autoProcessQueue === true ? this.autoProcessQueue : false,
                parallelUploads: this.parallelUploads || 1,
                uploadMultiple: this.uploadMultiple === true ? this.uploadMultiple : true,

                accept: (file: DropzoneFile, done: (error?: string | Error) => void) => {
                    if (this.done) {
                        this.done({ file: file });
                    }

                    done();
                },
                drop: (event: DragEvent) => {
                        this.jQueryService
                            .getElement(this.dzId)
                            .removeClass("a-ds-dragover");

                    if (this.drop instanceof Function) {
                        this.$scope.$apply(this.drop({event: event}));
                    } else {
                        this.$scope.$apply();
                    }
                },
                dragstart: (event: DragEvent) => {
                    if (this.dropStart instanceof Function)
                        this.$scope.$apply(this.dropStart({event: event}));
                    else {
                        this.$scope.$apply();
                    }
                },
                dragend: (event: DragEvent) => {
                    if (this.dragEnd instanceof Function) {
                        this.$scope.$apply(this.dragEnd({event: event}));
                    } else {
                        this.$scope.$apply();
                    }
                },
                dragenter: (event: DragEvent) => {
                    this.jQueryService
                        .getElement(this.dzId)
                        .addClass("a-ds-dragover");

                    if (this.dragEnter instanceof Function) {
                        this.$scope.$apply(this.dragEnter({event: event}));
                    } else {
                        this.$scope.$apply();
                    }
                },
                dragover: (event: DragEvent) => {
                    if (this.dragOver instanceof Function) {
                        this.$scope.$apply(this.dragOver({event: event}));
                    } else {
                        this.$scope.$apply();
                    }
                },
                dragleave: (event: DragEvent) => {
                    this.jQueryService
                        .getElement(this.dzId)
                        .removeClass("a-ds-dragover");
                    
                    if (this.dragLeave instanceof Function) {
                        this.$scope.$apply(this.dragLeave({event: event}));
                    } else {
                        this.$scope.$apply();
                    }
                },
                addedfile: (file: DropzoneFile) => {
                    if (this.addedFile instanceof Function) {
                        this.$scope.$apply(this.addedFile({file: file}));
                    }
                },
                removedfile: (file: DropzoneFile) => {
                    if (this.removedFile instanceof Function) {
                        this.$scope.$apply(this.removedFile({file: file}));
                    }
                },
                thumbnail: (file: DropzoneFile) => {
                    if (this.thumbnail instanceof Function) {
                        this.$scope.$apply(this.thumbnail({file: file}));
                    } else {
                        this.$scope.$apply();
                    }
                },
                error: (file: DropzoneFile, response: string | Error, xhr: XMLHttpRequest) => {
                    // must use apply because dropzone is not built angular way
                    this.$scope.$apply(() => {
                        if (this.error instanceof Function) {
                            this.error({
                                file: file, 
                                response: response, 
                                xhr: xhr
                            });
                        }

                        let errorMessage = '';

                        if (response && response['Message']) {
                            errorMessage = (response as any).Message;
                        } else {
                            errorMessage = response.toString();
                        }

                        this.toastMessageCreator.createErrorMessage(errorMessage);
                    });                    
                },
                processing: (file: DropzoneFile) => {
                    if (this.processing instanceof Function) {
                        this.processing({file: file});
                    }

                    this.jQueryService
                        .getElement(this.dzId)
                        .children('.progress')
                        .show();
                },
                uploadprogress: (dzFile: DropzoneFile) => {
                    if (this.uploadProgress instanceof Function) {
                        this.uploadProgress({dzFile: dzFile});
                    }
                },
                sending: (file: DropzoneFile, xhr: XMLHttpRequest, formData: FormData) => {
                    if (this.sending instanceof Function) {
                        this.sending({file: file, xhr: xhr, formData: formData});
                    } else {
                        this.$scope.$apply();
                    }
                }, 
                success: (file: DropzoneFile) => {
                    if (this.success instanceof Function) {
                        this.$scope.$apply(this.success({file: file, response: file?.xhr?.response.replace('[', '').replace(']','')}));
                    } else {
                        this.$scope.$apply();
                    }
                },
                complete: (file: DropzoneFile) => {
                    if (this.complete) {
                        this.$scope.$apply(() => {
                            this.complete({ file: file });
                            this.currentPercentComplete = 0;
                        });
                    } else {
                        this.$scope.$apply();
                    }
                }, 
                canceled: (file: DropzoneFile) => {
                    if (this.canceled instanceof Function) {
                        this.$scope.$apply(this.canceled({file: file}));
                    } else {
                        this.$scope.$apply();
                    }
                },
                maxfilesexceeded: (file: DropzoneFile) => {
                    if (this.maxFilesExceeded instanceof Function) {
                        this.$scope.$apply(this.maxFilesExceeded({ file: file }));
                    } else {
                        this.$scope.$apply();
                    }
                },
                maxfilesreached: (files: DropzoneFile[]) => {
                    if (this.maxFilesSearched instanceof Function) {
                        this.$scope.$apply(this.maxFilesSearched({ file: files }));
                    } else {
                        this.$scope.$apply();
                    }
                },
                reset: () => {
                    if (this.reset instanceof Function) {
                        this.reset();
                    }
                },
                totaluploadprogress: (upload: number) => {
                    if (this.totalUploadProgress instanceof Function) {
                        this.totalUploadProgress({ upload: upload });
                        this.percentComplete = upload;
                    }
                },
                queuecomplete: () => {
                    if (this.queueComplete instanceof Function) {
                        this.$scope.$apply(() => {
                            this.queueComplete();
                            this.percentComplete = 0;
                        });
                    } else {
                        this.$scope.$apply();
                    }

                    if (this.showProgress) {
                        this.jQueryService
                            .getElement(this.dzId)
                            .children('.progress')
                            .hide();
                    }
                }
            }
        );
    }

    public $onInit = () => {

    }
}

type DropZoneDirectiveScope = IScope & {
    dzId: string;
} 

const dropZoneDirective = ($compile: ICompileService) => {

    const link = (
        scope: DropZoneDirectiveScope, 
        elem: JQuery, 
        attrs: IAttributes,
        controller: DropZoneController
    ) => {

        controller.dzId = 'dz-' + scope.$id;
        attrs.$set('id', controller.dzId);

        $compile(elem)(scope);

        controller.initializeDropzone();
    };

    return {
        restrict: 'E',
        replace: true,
        scope: {},
        bindToController: {
            acceptedFiles: '@?',
            addedFile: '&?',
            addFilesStart: '&?',
            autoProcessQueue: '@?',
            canceled: '&?',
            complete: '&?',
            createImageThumbnails: '@?',
            dropZone: '=?',
            drop: '&?',
            dropStart: '&?',
            dragEnd: '&?',
            dragEnter: '&?',
            dragOver: '&?',
            dragLeave: '&?',
            done: '&?',
            dzId: '@?',
            error: '&?',
            formData: '&?',
            files: '=?',
            maxFiles: '@?',
            maxFilesSearched: '&?',
            maxFilesExceeded: '&?',
            maxFileSize: '@?',
            parallelUploads: '@',
            processing: '&?',
            queueComplete: '&?',
            removedFile: '&?',
            reset: '&?',
            sending: '&?',
            success: '&?',
            thumbnail: '&?',
            totalUploadProgress: '&?',
            uploadingFiles: '=?',
            uploadMultiple: '@?',
            uploadProgress: '&?',
            currentPercentComplete: '=?',
            percentComplete: '=?',
            showProgress: '@?'
        },
        link: link,
        controller: DropZoneController,
        controllerAs: 'vm',
        templateUrl: 'app/components/dropzone/dropZone.html'
    };
}

app.directive('dropZone', dropZoneDirective);