import app from '../../main';
import { Injectables } from '../injectables';

export class SystemSettings {
    public readonly odataBaseUrl: string = 'https://api.agencymultiplied.com/';
    public readonly apiBaseUrl: string = 'https://api.agencymultiplied.com/api/';
    public readonly authBaseUrl: string = 'https://api.agencymultiplied.com/';
    public readonly documentEditorBaseUrl: string = 'https://docedit.agencymultiplied.com/';
    public readonly a3WebBaseUrl: string = 'https://a3.agencymultiplied.com/';
    public readonly ajgSsoLoginUrl: string = 'https://a3.agencymultiplied.com/auth/Ajg/Login';
    public readonly environment: string = 'Prod';
}

app.constant(Injectables.SystemSettings, new SystemSettings());